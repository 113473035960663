import { createContext, useState, useContext, useEffect } from "react";
import type { AlertColor } from "@mui/material";

interface Alert {
  title?: string;
  message?: string;
  severity?: AlertColor;
}

interface AlertContext {
  alert: Alert[];
  onSetAlert: Function;
  onDeleteAlert: Function;
}

export const AlertContext = createContext<AlertContext>({
  alert: [],
  onSetAlert: () => {},
  onDeleteAlert: () => {},
});

export const useAlertContext = () => useContext(AlertContext);

export const AlertProvider = ({ children }: any) => {
  const [alert, setAlert] = useState<Alert[]>([]);

  const onSetAlert = (title: string, message: string, type: number) => {
    console.error(title, message);

    if (type === 0) {
      setAlert([...alert, { severity: "error", title, message }]);
    } else if (type === 1) {
      setAlert([...alert, { severity: "warning", title, message }]);
    } else if (type === 2) {
      setAlert([...alert, { severity: "info", title, message }]);
    } else if (type === 3) {
      setAlert([...alert, { severity: "success", title, message }]);
    } else {
      setAlert([...alert, { severity: "error", title, message }]);
    }

    setTimeout(() => {
      onDeleteAlert(alert.length - 1);
    }, 2000);
  };

  const onDeleteAlert = (value: number) => {
    setAlert(alert.filter((item, index) => index !== value));
  };

  const value = {
    alert,
    onSetAlert,
    onDeleteAlert,
  };

  return (
    <AlertContext.Provider value={value}>{children}</AlertContext.Provider>
  );
};
