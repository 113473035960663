import MD from "../components/md";
import style from "../scss/md.module.scss";
import MDfile from "../data/policy.md";
import { useEffect, useState } from "react";

const Policy = () => {
  const [file, setFile] = useState<string | null>(null);

  useEffect(() => {
    fetch(MDfile)
      .then((response) => response.text())
      .then((text: string) => {
        setFile(text);
      });
  }, []);

  return (
    <div>
      <section className={style.flex}>
        <MD md={file} />
      </section>
    </div>
  );
};

export default Policy;
