import React from "react";

const Error404 = () => {
  return (
    <div>
      <h2>404</h2>
      <p>ページが見つかりません。</p>
    </div>
  );
};

export default Error404;
