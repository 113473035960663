import React, { useEffect, useState } from "react";
import { Button, FormControl, TextField, Stack } from "@mui/material";
import type { UserLogin as UserLoginInterface } from "../interface";
import WForm from "./WForm";

export default function EditAccount({
  userLogin,
  setUserLogin,
  onConfirm = false,
}: {
  userLogin: UserLoginInterface;
  setUserLogin: Function;
  onConfirm: boolean;
}) {
  return (
    <Stack spacing={2}>
      <WForm
        required
        label="メールアドレス"
        value={userLogin?.email ?? ""}
        onChange={(e: any) => {
          setUserLogin({ ...userLogin, email: e.target.value });
        }}
      />
      <WForm
        required
        label="パスワード"
        value={userLogin?.password ?? ""}
        type="password"
        onChange={(e: any) => {
          setUserLogin({ ...userLogin, password: e.target.value });
        }}
      />
      {onConfirm ? (
        <WForm
          required
          error={userLogin?.password !== userLogin?.password2}
          label="パスワード確認"
          value={userLogin?.password2 ?? ""}
          type="password"
          helperText={
            userLogin?.password !== userLogin?.password2
              ? "同じパスワードを入力してください"
              : userLogin?.password2
              ? ""
              : "パスワードを再度入力してください"
          }
          onChange={(e: any) => {
            setUserLogin({ ...userLogin, password2: e.target.value });
          }}
        />
      ) : (
        <></>
      )}
    </Stack>
  );
}
