import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

const DForm = (props: any) => {
  return (
    <Box sx={{ width: "100%" }}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DesktopDatePicker
          inputFormat="YYYY/MM/DD"
          required={props.required}
          renderInput={(params) => (
            <TextField
              fullWidth
              required={props.required}
              {...params}
              helperText={
                props.required
                  ? props.value
                    ? ""
                    : `${props.label}を入力してください`
                  : ""
              }
            />
          )}
          fullWidth
          {...props}
        />
      </LocalizationProvider>
    </Box>
  );
};

export default DForm;
