import { useContext, useState } from "react";
import {
  Typography,
  Button,
  Container,
  Box,
  CssBaseline,
  Avatar,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../auth";
import EditAccount from "./EditAccount";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import type { UserLogin as UserLoginInterface } from "../interface";
import { postUser, sendEmail, signUp } from "../firebase";
import { AlertContext } from "../alert";

export default function Register() {
  const navigate = useNavigate();
  const { user, onSetLoading } = useContext(AuthContext);
  const { onSetAlert } = useContext(AlertContext);
  const [userLogin, setUserLogin] = useState<UserLoginInterface>({});

  const validate = () => {
    let count = 0;
    if (userLogin.email === undefined || userLogin.email?.length === 0)
      count += 1;
    if (userLogin.password === undefined || userLogin.password?.length === 0)
      count += 1;
    if (userLogin.password2 === undefined || userLogin.password2?.length === 0)
      count += 1;
    if (count > 0) return `入力していない項目が${count}件あります。`;
    if (userLogin.password === undefined || userLogin.password?.length < 6)
      return `パスワードは6桁以上入力してください。`;
    if (userLogin.password2 !== userLogin.password)
      return `パスワードが一致していません。`;
    else return null;
  };

  const handleComplete = async () => {
    onSetLoading(true);
    try {
      const message = validate();
      if (message) {
        onSetLoading(false);
        onSetAlert(null, message, 0);
        return;
      }
      if (userLogin?.email && userLogin?.password) {
        const data = await signUp(userLogin.email, userLogin.password);
        if (data.uid) {
          const result = await postUser({
            email: userLogin.email,
            userId: data.uid,
          });
          if (result) {
            const res = await sendEmail(data);
            onSetAlert(null, "確認メールを送信しました", 3);
            navigate({ pathname: "/" });
            return;
          } else {
            onSetAlert("エラー", "ユーザーを作成できませんでした", 0);
            // const res = await deleteUser(data);
            // if (res) onSetAlert(null, "ユーザーを削除しました。", 3);
            return;
          }
        } else {
          onSetAlert("エラー", "新規登録できませんでした。", 0);
          // const res = await DeleteUser(data);
          // if (res) onSetAlert(null, "ユーザーを削除しました。", 3);
          return;
        }
      }
    } catch (e) {
      onSetAlert(null, e, 0);
    } finally {
      onSetLoading(false);
    }
  };

  return (
    <Container component="main" sx={{ py: 12 }} maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: "warning.main" }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          新規登録
        </Typography>
        <Box sx={{ mt: 1, width: "100%" }}>
          <EditAccount
            userLogin={userLogin}
            setUserLogin={setUserLogin}
            onConfirm={true}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            onClick={handleComplete}
            sx={{ mt: 3, mb: 2 }}
          >
            新規登録する
          </Button>
          <Button
            fullWidth
            color="secondary"
            sx={{ mb: 2 }}
            onClick={() => {
              navigate({
                pathname: "/login",
              });
              return;
            }}
          >
            既存のアカウントでログイン
          </Button>
        </Box>
      </Box>
    </Container>
  );
}
