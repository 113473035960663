import { useContext, useEffect, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { AuthContext } from "../auth";
import { Container, Box, Fab, CircularProgress } from "@mui/material";
import { Add } from "@mui/icons-material";
import HomePoster from "./HomePoster";
import { getEvents, getUser } from "../firebase";
import type {
  Event as EventInterface,
  Theme as ThemeInterface,
} from "../interface";
import { AlertContext } from "../alert";

const Home = () => {
  return <Navigate to="/intro" />;
  const navigate = useNavigate();
  const { user, onSetLoading, loading, userData } = useContext(AuthContext);
  const { onSetAlert } = useContext(AlertContext);
  const [events, setEvents] = useState<EventInterface[]>([]);

  useEffect(() => {
    onSetLoading(true);
    const onload = async () => {
      try {
        if (!user) {
          navigate({
            pathname: "/login",
          });
          return;
        }
      } catch (e) {
        onSetAlert(null, e, 0);
      } finally {
        onSetLoading(false);
      }
    };
    onload();
  }, [user]);

  useEffect(() => {
    onSetLoading(true);
    const onload = async () => {
      try {
        if (userData?.nickname === undefined) {
          navigate({
            pathname: "/register",
          });
          return;
        }

        const data = await getEvents();
        if (data) {
          setEvents(data);
        }
      } catch (e) {
        onSetAlert(null, e, 0);
      } finally {
        onSetLoading(false);
      }
    };
    if (userData) {
      onload();
    } else {
      onSetLoading(false);
    }
  }, [userData]);

  if (!user && !loading) {
    return <Navigate to="/login" />;
  }

  return (
    <Container
      sx={{
        py: "0 !important",
        px: "0 !important",
        height: "90vh",
        width: "100vw",
      }}
    >
      <Box id="board">
        {events?.map((event, index) => {
          return event?.end !== true ? (
            <HomePoster event={event} key={index} />
          ) : (
            <span key={index}></span>
          );
        })}
      </Box>
      <Fab
        id="addPoster"
        color="primary"
        onClick={() => {
          navigate({
            pathname: "/eventAdd",
          });
          return;
        }}
      >
        <Add />
      </Fab>
    </Container>
  );
};

export default Home;
