import { Typography, Avatar, AvatarGroup, Grid } from "@mui/material";
import type { User as UserInterface } from "../interface";

const UserJoin = ({ users }: { users: UserInterface[] }) => {
  return (
    <Grid container alignItems="center">
      <Grid item xs={6} md={6}>
        <Typography variant="subtitle1">
          {users ? users?.length + "名が参加中" : ""}
        </Typography>
      </Grid>
      <Grid item xs={6} md={6} textAlign={"right"}>
        <AvatarGroup total={users?.length}>
          {users?.map((value, indexs) => {
            return (
              <Avatar
                alt={value?.nickname}
                key={indexs}
                sx={{ width: 36, height: 36, bgcolor: "secondary.main" }}
              >
                {value?.nickname ? value?.nickname[0] : ""}
              </Avatar>
            );
          })}
        </AvatarGroup>
      </Grid>
    </Grid>
  );
};

export default UserJoin;
