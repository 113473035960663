import React, { useEffect } from "react";
import { Box, Card, CardMedia } from "@mui/material";

const Cover = ({
  preview,
  onSaveEvent,
}: {
  preview: any;
  onSaveEvent: Function;
}) => {
  useEffect(() => {
    const cover = document.getElementById("cover");
    let item = document.getElementById("moveImage");
    let rotate = 0;

    const onMouseMove = (e: MouseEvent) => {
      e.preventDefault();
      if (item) {
        item.style.left = e.clientX - 100 + "px";
        item.style.top = e.clientY - 100 + "px";
      }
    };
    const onContextMenu = (e: MouseEvent) => {
      e.preventDefault();
      if (item) {
        if (rotate === 25) {
          rotate = -25;
        } else {
          rotate += 5;
        }
        item.style.transform = `rotate(${rotate}deg)`;
      }
    };
    const onClick = (e: MouseEvent) => {
      e.preventDefault();
      if (item && cover) {
        let clickX = e.pageX;
        let clickY = e.pageY;
        let element = e.target as HTMLElement;
        // 要素の位置を取得
        let clientRect = cover.getBoundingClientRect();
        let positionX = clientRect.left + window.scrollX;
        let positionY = clientRect.top + window.scrollY;

        // 要素内におけるクリック位置を計算;
        let x = clickX - positionX;
        let y = clickY - positionY;
        onSaveEvent(x, y, rotate);
      }
    };

    if (cover) {
      cover.addEventListener("mousemove", onMouseMove);
      cover.addEventListener("contextmenu", onContextMenu);
      cover.addEventListener("click", onClick);

      return function () {
        cover.removeEventListener("mousemove", onMouseMove);
        cover.removeEventListener("contextmenu", onContextMenu);
        cover.removeEventListener("click", onClick);
      };
    }
  }, []);

  return (
    <Box id="cover">
      <Card id="moveImage" className="posterCard">
        <CardMedia component="img" image={`paste.png`} />
      </Card>
    </Box>
  );
};

export default Cover;
