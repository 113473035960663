import { createContext, useContext, useState, useEffect, useMemo } from "react";
import { auth, AuthUser, getUser } from "./firebase";
import { User as UserInterface } from "./interface";

interface AuthContext {
  user: AuthUser | null | undefined;
  userData: UserInterface | null;
  loading: boolean;
  onSetLoading: Function;
}

export const AuthContext = createContext<AuthContext>({
  user: null,
  userData: null,
  loading: true,
  onSetLoading: () => {},
});

export const useAuthContext = () => useContext(AuthContext);

export const AuthProvider = ({ children }: any) => {
  const [user, setUser] = useState<AuthUser | null>(null);
  const [userData, setUserData] = useState<UserInterface | null>(null);
  const [loading, setLoading] = useState(true);

  const onSetLoading = (set: boolean) => {
    setLoading(set);
  };

  const value = useMemo(
    () => ({
      user,
      userData,
      loading,
      onSetLoading,
    }),
    [user, loading, userData]
  );

  useEffect(() => {
    const unsubscribed = auth.onAuthStateChanged(
      async (authUser: AuthUser | null) => {
        setLoading(true);
        if (authUser) {
          setUser(authUser);
          const data = await getUser(authUser.uid);
          if (data) {
            setUserData(data);
          } else {
            setUserData({});
          }
        } else {
          setUser(null);
          setUserData(null);
        }
        setLoading(false);
      }
    );

    return () => {
      unsubscribed();
      onSetLoading(false);
    };
  }, []);

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
