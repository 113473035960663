import style from "../styles/custom.module.css";
import { Avatar, Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import school from "../data/school.json";
import grade from "../data/grade.json";
import DForm from "./DForm";
import WForm from "./WForm";
import WSelect from "./WSelect";

const EditProfile = ({ userData, defaultData, setUserData }: any) => {
  return (
    <Stack spacing={2}>
      <Avatar
        alt={userData?.displayName}
        sx={{ width: 80, height: 80, m: "auto", bgcolor: "secondary.main" }}
      >
        {userData?.nickname ? userData?.nickname.charAt(0) : ""}
      </Avatar>
      <WForm
        required
        label="名前"
        variant="outlined"
        value={userData?.name ?? ""}
        onChange={(e: any) => {
          setUserData((prevState: object) => ({
            ...prevState,
            name: e?.target?.value,
          }));
        }}
      />
      <WForm
        required
        label="ハンドルネーム"
        variant="outlined"
        value={userData?.nickname ?? ""}
        onChange={(e: any) => {
          setUserData((prevState: object) => ({
            ...prevState,
            nickname: e?.target?.value,
          }));
        }}
      />
      <DForm
        label="誕生日"
        required
        value={userData?.birthday ?? ""}
        inputFormat="YYYY/MM/DD"
        onChange={(value: any) => {
          setUserData((prevState: object) => ({
            ...prevState,
            birthday: value,
          }));
        }}
      />
      <WSelect
        required
        label="所属"
        variant="outlined"
        value={userData?.schoolId ?? ""}
        items={school.data.map((value, index) => {
          return { value: value.schoolId, label: value.schoolName };
        })}
        onChange={(e: any) => {
          setUserData((prevState: object) => ({
            ...prevState,
            schoolId: e?.target?.value,
          }));
        }}
      />
      <WSelect
        required
        label="学年"
        variant="outlined"
        value={userData?.grade ?? ""}
        items={grade.data.map((value, index) => {
          return { value: index, label: value };
        })}
        onChange={(e: any) => {
          setUserData((prevState: object) => ({
            ...prevState,
            grade: e?.target?.value,
          }));
        }}
      />
      <WForm
        multiline
        rows={4}
        label="自己紹介"
        variant="outlined"
        value={userData?.profile ?? ""}
        onChange={(e: any) => {
          setUserData((prevState: object) => ({
            ...prevState,
            profile: e?.target?.value,
          }));
        }}
      />
    </Stack>
  );
};

export default EditProfile;
