import { getApps, initializeApp } from "firebase/app";
import {
  getAuth,
  sendEmailVerification,
  User,
  browserSessionPersistence,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  isSignInWithEmailLink,
  signInWithEmailLink,
} from "firebase/auth";
import {
  getFunctions,
  HttpsCallable,
  httpsCallable,
  HttpsCallableResult,
  connectFunctionsEmulator,
} from "firebase/functions";
import axios from "axios";
// import { getDateString } from "@/datetime";
import { getAnalytics } from "firebase/analytics";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import type {
  Event as EventInterface,
  Theme as ThemeInterface,
  User as UserInterface,
} from "./interface";

declare global {
  let REACT_APP_FIREBASE_API_KEY: string;
  let REACT_APP_FIREBASE_AUTH_DOMAIN: string;
  let REACT_APP_FIREBASE_PROJECT_ID: string;
  let REACT_APP_FIREBASE_STORAGE_BUCKET: string;
  let REACT_APP_FIREBASE_MESSAGING_SENDER_ID: string;
  let REACT_APP_FIREBASE_APP_ID: string;
  let REACT_APP_FIREBASE_MEASUREMENT_ID: string;
  let REACT_APP_FIREBASE_STORAGE_URL: string;
}

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY ?? REACT_APP_FIREBASE_API_KEY,
  authDomain:
    process.env.REACT_APP_FIREBASE_AUTH_DOMAIN ??
    REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId:
    process.env.REACT_APP_FIREBASE_PROJECT_ID ?? REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket:
    process.env.REACT_APP_FIREBASE_STORAGE_BUCKET ??
    REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId:
    process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID ??
    REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  measurementId:
    process.env.REACT_APP_FIREBASE_MEASUREMENT_ID ??
    REACT_APP_FIREBASE_MEASUREMENT_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID ?? REACT_APP_FIREBASE_APP_ID,
};

const app =
  typeof window !== "undefined" && !getApps().length
    ? initializeApp(firebaseConfig)
    : getApps()[0];
const analytics = getAnalytics(app);
const storage = getStorage(app);

export const auth = app ? getAuth(app) : getAuth(initializeApp(firebaseConfig));

auth.setPersistence(browserSessionPersistence);

export const functions = getFunctions();
// connectFunctionsEmulator(functions, "localhost", 5001);

export type AuthUser = User;

export const signIn = async (email: string, password: string) => {
  const res = await signInWithEmailAndPassword(auth, email, password);
  return res;
};

export const signUp = async (email: string, password: string) => {
  const userCredential = await createUserWithEmailAndPassword(
    auth,
    email,
    password
  );
  const user = userCredential.user;
  return user;
};

export const sendEmail = async (user: AuthUser) => {
  const res = await sendEmailVerification(user);
  return res;
};

export const { currentUser } = auth;

export const signOut = async () => {
  await auth.signOut();
};

export const getToken = async () => {
  if (auth && auth.currentUser) {
    const res = await auth.currentUser.getIdToken();
    return res;
  } else return null;
};

interface HttpsResponse extends HttpsCallableResult {
  readonly data: any[];
}

const getURL = (path: string) => {
  return (
    // `https://us-central1-splannt-dev.cloudfunctions.net/` + path
    `https://us-central1-splannt.cloudfunctions.net/` + path
    // `http://localhost:5001/${process.env.REACT_APP_FIREBASE_PROJECT_ID}/us-central1/` +
    // path
    // `http://localhost:5000/${process.env.REACT_APP_FIREBASE_PROJECT_ID}/us-central1/` +
    // path
  );
};

export const getData = async (ownerId: string) => {
  try {
    const token = await getToken();
    if (token) {
      const data = await axios.get(getURL("chart/" + ownerId), {
        withCredentials: true,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (data) return data.data;
    }
    return [];
  } catch (e) {
    console.error(e);
  }
};

export const getImage = async (path: string) => {
  const storageRef = ref(storage, path);
  const res = await getDownloadURL(storageRef);
  return res;
};

export const postImage = async (
  path: string,
  imageData: Blob | Uint8Array | ArrayBuffer
) => {
  try {
    const storageRef = ref(storage, path);
    const res = await uploadBytes(storageRef, imageData);
    return res;
  } catch (e) {
    console.error(e);
  }
};

export const postEvents = async (eventData: EventInterface) => {
  try {
    const token = await getToken();
    if (token) {
      const data = await axios.post(getURL("events"), eventData, {
        withCredentials: true,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (data?.data) {
        return data.data;
      }
    }
    return {};
  } catch (e) {
    console.error(e);
  }
};

export const getEvents = async () => {
  try {
    const token = await getToken();
    if (token) {
      const data = await axios.get(getURL("events"), {
        withCredentials: true,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (data) {
        let sortedData = data.data.sort(
          (a: EventInterface, b: EventInterface) => {
            if (
              a?.eventTime != null &&
              b?.eventTime != null &&
              a?.eventTime?.timeFrom < b?.eventTime?.timeFrom
            ) {
              return -1;
            }
          }
        );

        return sortedData;
      }
    }
    return [];
  } catch (e) {
    console.error(e);
  }
};

export const getEvent = async (eventId: string) => {
  try {
    const token = await getToken();
    if (token) {
      const data = await axios.get(getURL(`events/${eventId}`), {
        withCredentials: true,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (data) return data.data;
    }
    return {};
  } catch (e) {
    console.error(e);
  }
};

export const putEvent = async (eventId: string, eventData: EventInterface) => {
  try {
    const token = await getToken();
    if (token) {
      const data = await axios.put(getURL(`events/${eventId}`), eventData, {
        withCredentials: true,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (data) return data.data;
    }
    return {};
  } catch (e) {
    console.error(e);
  }
};

export const postUser = async (userData: UserInterface) => {
  try {
    const token = await getToken();
    if (token) {
      const data = await axios.post(getURL("users"), userData, {
        withCredentials: true,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (data?.data) {
        return data.data;
      }
    }
    return {};
  } catch (e) {
    console.error(e);
  }
};

export const putJoin = async (eventId: string, userId: string) => {
  try {
    const token = await getToken();
    if (token) {
      const data = await axios.put(
        getURL(`join/${eventId}`),
        { userId },
        {
          withCredentials: true,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (data?.data) {
        return data.data;
      }
    }
    return {};
  } catch (e) {
    console.error(e);
  }
};

export const putUser = async (userId: string, userData: UserInterface) => {
  try {
    const token = await getToken();
    if (token) {
      const data = await axios.put(getURL(`users/${userId}`), userData, {
        withCredentials: true,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (data) {
        return data.data;
      } else {
        const res = await postUser({
          userId,
        });
        if (res) return res.data;
      }
    }
    return {};
  } catch (e) {
    console.error(e);
  }
};

export const getUser = async (userId: string) => {
  try {
    const token = await getToken();
    if (token) {
      const data = await axios.get(getURL(`users/${userId}`), {
        withCredentials: true,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (data) {
        return data.data;
      } else {
        const res = await postUser({
          userId,
        });
        if (res) return res.data;
      }
    }
    return {};
  } catch (e) {
    console.error(e);
  }
};

export const getLocation = (locationId: string) => {
  try {
    // const token = await getToken();
    // if (token) {
    // const data = await axios.get(getURL(`users/${userId}`), {
    //   withCredentials: true,
    //   headers: {
    //     Authorization: `Bearer ${token}`,
    //   },
    // });
    // if (data) return data.data;
    // return "会津大学";
    // }
    return "会津大学";
  } catch (e) {
    console.error(e);
  }
};
