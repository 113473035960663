import React, { useContext } from "react";
import { Box, CircularProgress } from "@mui/material";
import { AuthContext } from "../auth";

const Loading = () => {
  const { loading } = useContext(AuthContext);
  if (loading) {
    return (
      <Box id="loading">
        <CircularProgress />
      </Box>
    );
  }
  return <></>;
};

export default Loading;
