import {
  Card,
  CardMedia,
  CardActionArea,
  Box,
  CardContent,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import type { Event as EventInterface } from "../interface";

const HomePoster = ({ event }: { event: EventInterface }) => {
  const navigate = useNavigate();
  const pins = ["✊", "😎", "👾"];
  const colors = ["#fdda7f", "#b9d0e6", "#7fa699"];
  const pin = pins[Math.floor(Math.random() * pins.length)];
  const color = colors[Math.floor(Math.random() * colors.length)];

  return (
    <Card
      className="posterCard"
      style={{
        top: event.position?.y ?? `${50 + Math.floor(Math.random() * 600)}px`,
        left: event.position?.x ?? `${50 + Math.floor(Math.random() * 1100)}px`,
        transform: event.position?.rotate
          ? `rotate(${event.position?.rotate?.toString()}deg)`
          : `rotate(${Math.floor(-25 + Math.random() * 50)}deg)`,
        filter:
          event?.eventTime?.timeTo !== null &&
          new Date(String(event?.eventTime?.timeTo)) >= new Date()
            ? `unset`
            : `brightness(60%)`,
        zIndex:
          event?.eventTime?.timeTo !== null &&
          new Date(String(event?.eventTime?.timeTo)) >= new Date()
            ? 1
            : `unset`,
      }}
    >
      <Box className="pin">{pin}</Box>
      <CardActionArea
        onClick={() => {
          navigate({
            pathname: "/event",
            search: `?eventId=${event.eventId}`,
          });
          return;
        }}
      >
        {event.imagePath ? (
          <CardMedia
            component="img"
            image={event.imagePath}
            sx={{ backgroundColor: color }}
          />
        ) : (
          <CardContent sx={{ backgroundColor: color }}>
            <Typography gutterBottom variant="h5" component="p">
              {event.eventName}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {event.content}
            </Typography>
          </CardContent>
        )}
      </CardActionArea>
    </Card>
  );
};

export default HomePoster;
