import {
  Typography,
  Avatar,
  AvatarGroup,
  Grid,
  Link,
  Box,
  Stack,
} from "@mui/material";
import type { Event as EventInterface } from "../interface";
import { Place } from "@mui/icons-material";
import { getLocation } from "../firebase";
import { dateTimeToString } from "./datetime";

const EventDetail = ({ event }: { event: EventInterface }) => {
  return (
    <>
      {event ? (
        <Box
          sx={{
            borderTop: "1px solid rgba(0,0,0,.12)",
            py: 2,
            wordWrap: "break-word",
          }}
        >
          <Stack direction="column" spacing={3} sx={{ pt: 2 }}>
            {event.eventName ? (
              <Stack direction="column" spacing={1}>
                <Typography variant="subtitle1">タイトル</Typography>
                <Typography variant="h5">{event.eventName}</Typography>
              </Stack>
            ) : (
              <></>
            )}
            {event.eventTime?.timeFrom ? (
              <Stack direction="column" spacing={1}>
                <Typography variant="subtitle1">開始時間</Typography>
                <Typography variant="body1">
                  {dateTimeToString(event.eventTime.timeFrom)}
                </Typography>
              </Stack>
            ) : (
              <></>
            )}
            {event.eventTime?.timeTo ? (
              <Stack direction="column" spacing={1}>
                <Typography variant="subtitle1">終了時間</Typography>
                <Typography variant="body1">
                  {dateTimeToString(event.eventTime.timeTo)}
                </Typography>
              </Stack>
            ) : (
              <></>
            )}
            {event.content ? (
              <Stack direction="column" spacing={1}>
                <Typography variant="subtitle1">詳細</Typography>
                <pre>
                  <Typography variant="body1">
                    {event.content.replaceAll("\\n", "\n")}
                  </Typography>
                </pre>
              </Stack>
            ) : (
              <></>
            )}
            {event.location || event.locationId ? (
              <Stack direction="column" spacing={1}>
                <Typography variant="subtitle1">場所</Typography>
                <Stack direction="row" spacing={1} alignItems="center">
                  <Place fontSize="inherit" sx={{ mr: 1 }} />
                  {event.locationId || event.location ? (
                    <Link
                      underline="none"
                      href={`https://www.google.com/maps/search/${
                        event.location ??
                        getLocation(event.locationId ?? "") ??
                        ""
                      }`}
                    >
                      <Typography variant="body1">
                        {event.location ?? getLocation(event.locationId ?? "")}
                      </Typography>
                    </Link>
                  ) : (
                    <></>
                  )}
                </Stack>
              </Stack>
            ) : (
              <></>
            )}
            {event.url ? (
              <Stack direction="column" spacing={1}>
                <Typography variant="subtitle1">Webサイト</Typography>
                <Link className="url" underline="none" href={event.url}>
                  <Typography variant="body1">{event.url}</Typography>
                </Link>
              </Stack>
            ) : (
              <></>
            )}
          </Stack>
        </Box>
      ) : (
        <></>
      )}
    </>
  );
};

export default EventDetail;
