import { Typography, Link, Grid } from "@mui/material";
import { getLocation } from "../firebase";
import type {
  Event as EventInterface,
  Theme as ThemeInterface,
} from "../interface";
import { dateTimeToString } from "./datetime";
import Image from "mui-image";
import { getLocationName } from "../util";

const Poster = ({
  event,
  theme,
  onEdit,
}: {
  event: EventInterface | null;
  theme: ThemeInterface | null;
  onEdit: boolean;
}) => {
  const getStyle = (key: string, onHide: boolean | null = null) => {
    if (theme && theme[key]) {
      return {
        top: theme[key]["y"] ?? "unset",
        left: theme[key]["x"] ?? "unset",
        textAlign: theme[key]["align"] as "left",
        maxWidth: theme[key]["w"] ?? "none",
        fontWeight: theme[key]["fontWeight"],
        fontSize: theme[key]["size"],
        fontStyle: theme[key]["fontStyle"] ?? "normal",
        fontFamily: theme[key]["fontFamily"] ?? "inherit",
        lineHeight: theme[key]["lineHeight"] ?? "1.0",
        color: onHide ? "transparent" : theme[key]["color"] ?? "unset",
      };
    } else {
      return {};
    }
  };

  return (
    <>
      {event && theme && onEdit ? (
        <Grid
          item
          xs={12}
          className="poster"
          sx={{
            color: theme?.global?.color,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "center center",
            backgroundImage: `url(${theme.global.background})`,
          }}
        >
          <Typography gutterBottom style={getStyle("title")}>
            {event.eventName ? event.eventName : "タイトル未設定"}
          </Typography>
          {event.locationId || event.location ? (
            <Link
              style={getStyle("location")}
              underline="none"
              href={`https://www.google.com/maps/search/${
                event.locationId === "" || event.locationId === "99999"
                  ? event.location
                  : getLocationName(event.locationId ?? "") ?? ""
              }`}
            >
              in{" "}
              {event.locationId === "" || event.locationId === "99999"
                ? event.location
                : getLocationName(event.locationId ?? "") ?? ""}
            </Link>
          ) : (
            <></>
          )}
          {event?.eventTime?.timeFrom && theme ? (
            <Typography style={getStyle("timeFrom")}>
              {dateTimeToString(
                event.eventTime.timeFrom,
                theme.timeFrom?.format
              )}
            </Typography>
          ) : (
            <></>
          )}
          {event?.eventTime?.timeTo && theme ? (
            <Typography style={getStyle("timeTo")} className="title">
              ~{dateTimeToString(event.eventTime.timeTo, theme.timeTo?.format)}
            </Typography>
          ) : (
            <></>
          )}
          <Typography style={getStyle("content")}>
            {event.content ? event.content : ""}
          </Typography>
          {event.url ? (
            <Link
              className="url"
              style={getStyle("url")}
              underline="none"
              href={event.url}
            >
              {event.url}
            </Link>
          ) : (
            <></>
          )}
        </Grid>
      ) : (
        <Grid
          item
          xs={12}
          className="poster"
          sx={{
            color: theme?.global?.color,
          }}
        >
          <Image src={event?.imagePath ?? ""} alt={event?.eventId} />
          {theme && event?.locationId ? (
            <Link
              style={getStyle("location", true)}
              underline="none"
              href={`https://www.google.com/maps/search/${getLocation(
                event.locationId
              )}`}
            >
              in {getLocation(event.locationId)}
            </Link>
          ) : (
            <></>
          )}
          {theme && event?.url ? (
            <Link
              className="url"
              style={getStyle("url", true)}
              underline="none"
              href={event.url}
            >
              {event.url}
            </Link>
          ) : (
            <></>
          )}
        </Grid>
      )}
    </>
  );
};

export default Poster;
