import {
  Container,
  Link,
  Box,
  Typography,
  useScrollTrigger,
  Slide,
} from "@mui/material";

interface Props {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window?: () => Window;
  children: React.ReactElement;
}

function HideOnScroll(props: Props) {
  const { children, window } = props;
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
  });

  return (
    <Slide appear={true} direction="up" in={!trigger}>
      {children}
    </Slide>
  );
}

const Footer = () => {
  return (
    <HideOnScroll>
      <Box
        sx={{
          backgroundColor: "#e0ed9f",
          color: "#665257",
          justifyContent: "flex-end",
          textAlign: "right",
          p: 1,
          px: 2,
          transition: `box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms`,
          boxShadow: `0px 2px 4px -1px rgb(0 0 0 / 20%),
    0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)`,
        }}
      >
        <Typography variant="caption">
          @Copyright
          <Link href="https://branchism.com/" target="_blank" sx={{ ml: 1 }}>
            株式会社ブランチズム
          </Link>
        </Typography>
      </Box>
    </HideOnScroll>
  );
};
export default Footer;
