import * as React from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

const WSelect = (props: any) => {
  return (
    <Box sx={{ width: "100%", my: 1 }}>
      <FormControl fullWidth variant="filled">
        <InputLabel id={props.label}>{props.label}</InputLabel>
        <Select {...props} value={props.value ?? ""} labelId={props.label}>
          <MenuItem value="" key={0}>
            <em>{props.label}</em>
          </MenuItem>
          {props?.items?.map((value: any, index: number) => (
            <MenuItem value={value.value} key={index + 1}>
              {value.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};

export default WSelect;
