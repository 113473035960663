import { useContext } from "react";
import { AlertContext } from "./alert";
import { IconButton, Alert, AlertTitle, Collapse } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { TransitionGroup } from "react-transition-group";

const Message = () => {
  const { alert, onDeleteAlert } = useContext(AlertContext);
  return (
    <div id="alert">
      <TransitionGroup>
        {alert ? (
          alert.map((value, index) => (
            <Collapse key={index}>
              <Alert
                severity={value?.severity ?? "error"}
                action={
                  <IconButton
                    color="inherit"
                    size="small"
                    onClick={() => {
                      onDeleteAlert(index);
                    }}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
              >
                <AlertTitle>{value?.title ?? ""}</AlertTitle>
                {value?.message?.toString() ?? ""}
              </Alert>
            </Collapse>
          ))
        ) : (
          <></>
        )}
      </TransitionGroup>
    </div>
  );
};

export default Message;
