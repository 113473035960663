import { Stack, IconButton } from "@mui/material";
import { Facebook, Twitter, Chat, FileDownload } from "@mui/icons-material";
import html2canvas from "html2canvas";
import { saveAs } from "file-saver";

const ShareLink = () => {
  const onClickExport = () => {
    const target = document.getElementById("poster");
    if (target) {
      html2canvas(target, {
        allowTaint: true,
        useCORS: true,
      }).then((canvas) => {
        canvas.toBlob(async (blob: Blob | null) => {
          if (blob) saveAs(blob, "splannt.png");
        });
      });
    }
  };

  return (
    <Stack
      direction="row"
      spacing={1}
      justifyContent="center"
      className="sharelink"
    >
      <IconButton
        href={`https://www.facebook.com/share.php?u=${window.location.href}`}
        target="_blank"
        color="secondary"
      >
        <Facebook />
      </IconButton>
      <IconButton
        href={`http://twitter.com/share?url=${window.location.href}&hashtags=Splannt`}
        target="_blank"
        color="primary"
      >
        <Twitter />
      </IconButton>
      <IconButton
        href={`https://line.me/R/msg/text/?${window.location.href}`}
        target="_blank"
        color="success"
      >
        <Chat />
      </IconButton>
      <IconButton onClick={onClickExport}>
        <FileDownload />
      </IconButton>
    </Stack>
  );
};

export default ShareLink;
