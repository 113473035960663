import { Route, Routes, BrowserRouter } from "react-router-dom";
import { useState, useEffect } from "react";
import Login from "./components/Login";
import Signup from "./components/Signup";
import Header from "./Header";
import Footer from "./Footer";
import Message from "./Message";
import Home from "./components/Home";
import Event from "./components/Event";
import EventAdd from "./components/EventAdd";
import Error404 from "./components/Error404";
import Intro from "./components/Intro";
import Policy from "./components/Policy";
import Terms from "./components/Terms";
import Loading from "./components/Loading";
import Register from "./components/Register";
import { AuthProvider } from "./auth";
import { AlertProvider } from "./alert";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers-pro";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import ja from "date-fns/locale/ja";

import "./App.css";

const theme = createTheme({
  typography: {
    subtitle1: {
      fontSize: 16,
      fontWeight: 600,
    },
  },
  palette: {
    primary: {
      main: "#41b5b7",
      contrastText: "#ffffff",
    },
    secondary: {
      main: "#427696",
      contrastText: "#ffffff",
    },
    info: {
      main: "#e0ed9f",
    },
    warning: {
      main: "#f9d682",
    },
    error: {
      main: "#d0607a",
      contrastText: "#ffffff",
    },
  },
  components: {
    MuiLink: {
      defaultProps: {
        color: "#000000",
      },
    },
  },
});

const App = () => {
  const [device, setDevice] = useState<string>("");

  useEffect(() => {
    const ua = window.navigator.userAgent.toLowerCase();
    if (
      ua.indexOf("iphone") > 0 ||
      ua.indexOf("ipod") > 0 ||
      (ua.indexOf("android") > 0 && ua.indexOf("mobile") > 0)
    ) {
      setDevice("sp");
    } else if (ua.indexOf("ipad") > 0 || ua.indexOf("android") > 0) {
      // iOS12 まで
      setDevice("tab");
    } else if (
      ua.indexOf("ipad") > -1 ||
      (ua.indexOf("macintosh") > -1 && "ontouchend" in document)
    ) {
      // iOS13 以降
      setDevice("tab");
    } else {
      setDevice("pc");
    }
  }, []);

  return (
    <BrowserRouter>
      <AlertProvider>
        <AuthProvider>
          <ThemeProvider theme={theme}>
            <LocalizationProvider
              dateAdapter={AdapterDateFns}
              adapterLocale={ja}
            >
              <span id={device === "sp" ? "sp" : "pc"}>
                <Message />
                <header id="header">
                  <Header />
                </header>
                <Loading />
                <Routes>
                  <Route path="/" element={<Home />} key={0} />
                  <Route path="/login" element={<Login />} key={1} />
                  <Route path="/signup" element={<Signup />} key={2} />
                  <Route path="/register" element={<Register />} key={3} />
                  <Route path="/event" element={<Event />} key={4} />
                  <Route path="/eventAdd" element={<EventAdd />} key={5} />
                  <Route path="*" element={<Error404 />} key={6} />
                  <Route path="/intro" element={<Intro />} key={7} />
                  <Route path="/policy" element={<Policy />} key={8} />
                  <Route path="/terms" element={<Terms />} key={9} />
                </Routes>
                <footer id="footer">
                  <Footer />
                </footer>
              </span>
            </LocalizationProvider>
          </ThemeProvider>
        </AuthProvider>
      </AlertProvider>
    </BrowserRouter>
  );
};

export default App;
