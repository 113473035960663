import style from "../scss/index.module.scss";
import "../scss/global.scss";

import A1 from "../images/top/話し合い.png";
import A2 from "../images/top/ランニング青.png";
import A3 from "../images/top/車椅子.png";
import A4 from "../images/top/お茶会.png";
import A5 from "../images/top/てるてる黄.png";
import A6 from "../images/top/ゴリラみたいなやつ.png";
import A7 from "../images/top/花たち.png";
import A8 from "../images/top/勉強会顔色あり.png";
import A9 from "../images/top/サッカー少年.png";
import A11 from "../images/top/顕微鏡.png";
import A12 from "../images/top/バンド.png";
import A13 from "../images/top/女の子1.png";
import A14 from "../images/top/宇宙人 2.png";
import A15 from "../images/top/てるてる青.png";
import B222 from "../images/ashiato.svg";
import B3 from "../images/top/だるま2.png";
import B4 from "../images/top/だるまどすこい.png";
import Scroll from "../images/top/scroll.svg";
import Push from "../images/push.svg";
import Push1 from "../images/push1.svg";
import Push2 from "../images/push2.svg";
import Push3 from "../images/push3.svg";
import Push4 from "../images/push4.svg";
import Logic from "../images/logic.svg";
import LogicB from "../images/green.svg";

import Chi from "../images/chi.svg";
import Chi2 from "../images/top/お茶会2.png";
import Dou from "../images/do.svg";
import Dou2 from "../images/top/イベント募集イラスト.png";
import Kan from "../images/kan.svg";
import Kan2 from "../images/top/花たち.png";
import C from "../images/comic.svg";

const Intro = () => {
  return (
    <div>
      <section className={`${style.flex} ${style.section_1}`}>
        <div className={style.title}>「Splannt」サービス終了のお知らせ</div>
        <div className={style.small}>
          Splanntをご愛顧いただきまして誠にありがとうございます。この度、一般学生へのサービス提供を停止することとなりました。2022年のサービス開始以来、ご愛顧いただきました皆様に感謝申し上げますとともに、終了へのご理解を賜りますようお願い申し上げます。
          サービス終了に伴い、サーバーに保存されている画像やメールアドレスなどのデータはすべて弊社にて削除いたします。
          ご不明な点がございましたら、弊社お問い合わせまでご連絡よろしくお願い申し上げます。
        </div>
      </section>
      <section className={`${style.flex} ${style.section_0}`}>
        <div>
          <img src={A1} alt="Splannt" style={{ top: "100px", left: "50px" }} />
          <img src={A2} alt="Splannt" style={{ top: "100px", left: "250px" }} />
          <img src={A3} alt="Splannt" style={{ top: "100px", left: "300px" }} />
          <img src={A7} alt="Splannt" style={{ top: "250px", left: "20px" }} />
          <img src={A9} alt="Splannt" style={{ top: "400px", left: "50px" }} />
          <img src={A11} alt="Splannt" style={{ top: "550px", left: "50px" }} />
          <img
            src={A12}
            alt="Splannt"
            style={{ top: "550px", left: "250px" }}
          />
          <img
            src={A4}
            alt="Splannt"
            style={{ top: "100px", right: "300px" }}
          />
          <img
            src={A6}
            alt="Splannt"
            style={{ top: "100px", right: "100px" }}
          />
          <img src={A8} alt="Splannt" style={{ top: "250px", right: "80px" }} />
          <img
            src={A5}
            alt="Splannt"
            style={{ top: "440px", right: "50px", width: "8%" }}
          />
          <img
            src={A13}
            alt="Splannt"
            style={{ top: "500px", right: "200px" }}
          />
          <img
            src={A14}
            alt="Splannt"
            style={{ top: "550px", right: "50px" }}
          />
        </div>
        <div className={style.title}>
          <div className={`${style.flexFont}`}>
            <span>突然変異は</span>
            <span>きっかけから</span>
          </div>
          <div>大学生のローカルアプリ</div>
        </div>
        <div className={style.small}>
          <img
            src={Scroll}
            alt="Splannt"
            style={{ position: "relative", width: "12px" }}
          />
          <p>Scroll</p>
        </div>
      </section>

      <section className={`${style.flex} ${style.section_1}`}>
        <img src={A15} alt="Splannt" style={{ top: "10%", right: "-25%" }} />
        <div className={style.small}>
          <div style={{ flexGrow: 1 }}>
            <div className={style.title}>こんなことができたらいいな</div>
            <p>色んな友達と繋がりたい</p>
            <p>自分の殻を破りたい</p>
            <p>自分の「好き」を共有したい</p>
            <p>自分のサークルをアピールしたい</p>
            <p>別次元の友達から刺激を受けたい一緒に行く人いないかな</p>
            <p>あなたのできたらいいなを叶えます</p>
          </div>
          <img
            src={B222}
            alt="Splannt"
            style={{ width: "50%", margin: "auto" }}
          />
        </div>
      </section>

      <section className={`${style.flex} ${style.section_2}`}>
        <div className={style.title}>
          あなたのチャレンジと成長をプッシュするスプラント
        </div>
        <div className={style.small}>
          <img src={B3} alt="Splannt" />
          <div className={style.button}>
            <a
              href="https://apps.apple.com/us/app/splannt-%E3%82%B9%E3%83%97%E3%83%A9%E3%83%B3%E3%83%88/id1618736216?itsct=apps_box_badge&itscg=30200"
              target="_blank"
              rel="noreferrer"
            >
              インストール
            </a>
          </div>
          <img src={B4} alt="Splannt" />
        </div>
      </section>

      <section className={`${style.flex} ${style.section_3}`}>
        <img
          src={Logic}
          alt="Splannt"
          style={{ width: "100%", height: "auto" }}
        />
        <div className={style.title}>
          <span>突然変異をもたらす</span>
          <span>3つのロジック</span>
        </div>
        <div className={style.small}>
          <div>
            <img src={Chi} alt="Splannt" />
            <h3>
              ネットにはない
              <br />
              身近な情報を得る
            </h3>
            <img src={Chi2} alt="Splannt" style={{ width: "100%" }} />
            <p>
              <span>
                スプラントに参加する人は同じ大学生で、自分の行動範囲内での人々です。
              </span>
              <span>ネットにはないローカルな情報がスプラントにあります。</span>
              <span>スプラントでしか得られない知識や情報が満載！</span>
            </p>
          </div>
          <div>
            <img src={Dou} alt="Splannt" />
            <h3>イベント企画</h3>
            <img src={Dou2} alt="Splannt" style={{ width: "70%" }} />
            <p>
              <span>
                自分だと絶対に行かない場所やイベントに参加してみよう！
              </span>
              <span>
                1人で行くより、それを本当に分かっている人と行くことでより理解が深まるかも?
              </span>
            </p>
          </div>
          <div>
            <img src={Kan} alt="Splannt" />
            <h3>
              同じ、別次元の
              <br />
              感性を持つ友達探し
            </h3>
            <img src={Kan2} alt="Splannt" style={{ width: "100%" }} />
            <p>
              <span>スプラントで、自分の趣味や考えなどを共有してみよう！</span>
              <span>そしたら意外と共感する仲間が集まるかもよ！</span>
              <span>自分にない世界に飛び込んでみよう！</span>
              <span>自分とは全く違う感性の人や事へコンタクトしてみよう！</span>
              <span>思わぬ化学反応をおこすかも！？</span>
            </p>
          </div>
        </div>
        <img
          src={LogicB}
          alt="Splannt"
          style={{ width: "100%", height: "auto" }}
        />
      </section>

      <section className={`${style.flex} ${style.section_4}`}>
        <div className={style.title}>突然変異をもたらす思わぬ出会い</div>
        <div className={style.small}>
          <img
            src={C}
            alt="Splannt"
            style={{ width: "100%", height: "auto" }}
          />
        </div>
        <div className={style.title}>あなたにも突然変異が起こるかも！？</div>
        <div className={style.button}>
          <a
            href="https://apps.apple.com/us/app/splannt-%E3%82%B9%E3%83%97%E3%83%A9%E3%83%B3%E3%83%88/id1618736216?itsct=apps_box_badge&itscg=30200"
            target="_blank"
            rel="noreferrer"
          >
            きっかけを探しに行く
          </a>
        </div>
      </section>

      <section className={`${style.flex} ${style.section_5}`}>
        <img
          src={Push}
          alt="Splannt"
          style={{ width: "100%", height: "auto" }}
        />
        <div className={style.title}>
          <span>あなたのチャレンジと</span>
          <span>成長を</span>
          <span>プッシュするアプリ</span>
        </div>
        <div className={style.small}>
          <div>
            <h3>イベント参加・募集</h3>
            <img src={Push1} alt="Splannt" />
            <div>
              今すぐ参加できるイベントが１画
              <br />
              面に、興味がありそうなトピック
              <br />
              を探してみよう。
            </div>
          </div>
          <div>
            <h3>おすすめ通知機能</h3>
            <img src={Push2} alt="Splannt" />
            <div>
              あなたの参加傾向から相性が合う
              <br />
              イベントをおすすめします。
            </div>
          </div>
          <div>
            <h3>大事な情報を逃さない</h3>
            <img src={Push3} alt="Splannt" />
            <div>
              コミュニティの情報を
              <br />
              １画面に収めました。
              <br />
              大学生活で必要な機能をアップデートで追加していきます。
            </div>
          </div>
          <div>
            <h3>安心・安全サポート</h3>
            <img src={Push4} alt="Splannt" />
            <div>
              ユーザーは全員認証済みの大学生 <br />
              キャンパス間で交流も可能に。
            </div>
          </div>
        </div>
      </section>

      <section className={`${style.flex} ${style.section_6}`}>
        <div className={style.title}>
          <span>ファーストアクションを</span>
          <span>スプラントに任せてみませんか？</span>
        </div>
        <div className={style.button}>
          <a
            href="https://apps.apple.com/us/app/splannt-%E3%82%B9%E3%83%97%E3%83%A9%E3%83%B3%E3%83%88/id1618736216?itsct=apps_box_badge&itscg=30200"
            target="_blank"
            rel="noreferrer"
          >
            インストール
          </a>
        </div>
      </section>

      <section className={`${style.flex} ${style.section_7}`}>
        <div className={style.title}>会社紹介</div>
        <div className={style.small}>
          株式会社ブランチズムは
          <br />
          オンラインとリアルを結ぶ
          <br />
          新世代のサービスで
          <br />
          出会いをアップデートします
        </div>
      </section>

      <section className={`${style.flex} ${style.section_8}`}>
        <div className={style.title}>お問い合わせ</div>
        <div className={style.small}>
          <p>株式会社ブランチズム</p>
          <p>広報担当</p>
          <p>Email: info@branchism.com</p>
          <p>営業時間: 09:00-17:00 平日</p>
        </div>
      </section>
    </div>
  );
};

export default Intro;
