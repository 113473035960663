import React, { useContext } from "react";
import {
  AppBar,
  Box,
  Toolbar,
  IconButton,
  Typography,
  Menu,
  Container,
  Avatar,
  Button,
  Tooltip,
  MenuItem,
  Link,
  useScrollTrigger,
  Slide,
} from "@mui/material";
import { Navigate, useNavigate } from "react-router-dom";
import { Menu as MenuIcon } from "@mui/icons-material";
import LogoSmall from "./images/logo250x250.png";
import Logo from "./logo_l.png";
import { AuthContext } from "./auth";
import { signOut } from "./firebase";

interface Props {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window?: () => Window;
  children: React.ReactElement;
}

function HideOnScroll(props: Props) {
  const { children, window } = props;
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
  });

  return (
    <Slide appear={true} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

const Header = () => {
  const navigate = useNavigate();
  const { user, userData, onSetLoading } = useContext(AuthContext);

  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(
    null
  );
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null
  );

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <HideOnScroll>
      <AppBar position="fixed" color="inherit">
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            <Box
              sx={{ display: { xs: "none", md: "flex" }, mr: 1, height: 60 }}
            >
              <Link href="/">
                <img src={Logo} style={{ height: "100%", width: "auto" }} />
              </Link>
            </Box>
            <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: "block", md: "none" },
                }}
              >
                <MenuItem key={0}>
                  <Link
                    href="/intro"
                    sx={{
                      margin: "5px 12px auto",
                      display: "block",
                      fontSize: "16px",
                      fontWeight: "400",
                    }}
                  >
                    スプラントについて
                  </Link>
                </MenuItem>
                <MenuItem key={1}>
                  <Link
                    href="/terms"
                    sx={{
                      margin: "5px 12px auto",
                      display: "block",
                      fontSize: "16px",
                      fontWeight: "400",
                    }}
                  >
                    利用規約
                  </Link>
                </MenuItem>
                <MenuItem key={2}>
                  <Link
                    href="/policy"
                    sx={{
                      margin: "5px 12px auto",
                      fontSize: "16px",
                      display: "block",
                      fontWeight: "400",
                    }}
                  >
                    プライバシーポリシー
                  </Link>
                </MenuItem>
              </Menu>
            </Box>
            <Box
              sx={{
                display: { xs: "flex", md: "none" },
                flexGrow: 1,
                mr: 1,
                height: 60,
              }}
            >
              <Link href="/">
                <img
                  src={LogoSmall}
                  style={{ height: "100%", width: "auto" }}
                />
              </Link>
            </Box>
            <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
              <Link
                href="/intro"
                sx={{
                  margin: "5px 12px auto",
                  display: "block",
                  fontSize: "16px",
                  fontWeight: "400",
                }}
              >
                スプラントについて
              </Link>
              <Link
                href="/terms"
                sx={{
                  margin: "5px 12px auto",
                  display: "block",
                  fontSize: "16px",
                  fontWeight: "400",
                }}
              >
                利用規約
              </Link>
              <Link
                href="/policy"
                sx={{
                  margin: "5px 12px auto",
                  fontSize: "16px",
                  display: "block",
                  fontWeight: "400",
                }}
              >
                プライバシーポリシー
              </Link>
            </Box>
            <Box sx={{ flexGrow: 0 }}>
              {user ? (
                <>
                  <Tooltip title="Open settings">
                    <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                      <Avatar
                        sx={{ bgcolor: "secondary.main" }}
                        alt={userData?.nickname ?? ""}
                        src="/static/images/avatar/2.jpg"
                      />
                    </IconButton>
                  </Tooltip>
                  <Menu
                    sx={{ mt: "45px" }}
                    id="menu-appbar"
                    anchorEl={anchorElUser}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    open={Boolean(anchorElUser)}
                    onClose={handleCloseUserMenu}
                  >
                    <MenuItem
                      key="Signout"
                      onClick={() => {
                        onSetLoading(true);
                        signOut();
                        navigate({
                          pathname: "/",
                        });
                        return;
                      }}
                    >
                      <Typography textAlign="center">ログアウト</Typography>
                    </MenuItem>
                  </Menu>
                </>
              ) : (
                <Box>
                  <Link href="/login" style={{ whiteSpace: "nowrap" }}>
                    ログイン
                  </Link>
                </Box>
              )}
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
    </HideOnScroll>
  );
};
export default Header;
