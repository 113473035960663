import React, { useState, useRef, useEffect, useContext } from "react";
import { Button } from "@mui/material";
import { ChangeEventHandler } from "react";

export const UploadButton = ({
  onChange,
  upload,
}: {
  onChange: ChangeEventHandler;
  upload: string;
}) => {
  const ref = useRef<HTMLInputElement>(null);

  return (
    <label htmlFor="inputFile">
      <input
        accept="image/*, .pdf"
        multiple
        type="file"
        id="inputFile"
        ref={ref}
        style={{ display: "none" }}
        onChange={onChange}
      />
      <Button
        color="secondary"
        variant="contained"
        onClick={() => {
          if (ref?.current) ref.current.click();
        }}
      >
        {upload}
      </Button>
    </label>
  );
};

export default UploadButton;
