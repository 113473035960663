import { useContext, useState } from "react";
import {
  Typography,
  Container,
  CssBaseline,
  Box,
  Avatar,
  Button,
} from "@mui/material";
import { Navigate, useNavigate } from "react-router-dom";
import { AuthContext } from "../auth";
import EditProfile from "./EditProfile";
import { Face } from "@mui/icons-material";
import type { User as UserInterface } from "../interface";
import { putUser, signUp } from "../firebase";
import { AlertContext } from "../alert";

const steps = ["ユーザー情報"];

const Register = () => {
  const navigate = useNavigate();
  const { user, onSetLoading } = useContext(AuthContext);
  const { onSetAlert } = useContext(AlertContext);
  const [userData, setUserData] = useState<UserInterface>({
    birthday: "1999/01/01",
  });

  const validate = () => {
    let count = 0;
    if (userData && userData?.name === undefined) count += 1;
    if (userData && userData?.nickname === undefined) count += 1;
    if (userData && userData?.birthday === undefined) count += 1;
    if (userData && userData?.schoolId === undefined) count += 1;
    if (userData && userData?.grade === undefined) count += 1;
    if (count > 0) return `入力していない項目が${count}件あります。`;
    else return null;
  };

  const handleComplete = async () => {
    onSetLoading(true);
    try {
      const message = validate();
      if (message) {
        onSetLoading(false);
        onSetAlert(null, message, 0);
        return;
      }
      if (user?.uid) {
        const result = await putUser(user.uid, {
          ...userData,
          userId: user.uid,
        });
        if (result) {
          onSetAlert(null, "ユーザー情報を登録しました。", 3);
          window.location.href = "/";
          return;
        } else {
          onSetAlert(null, "ユーザー情報を登録できませんでした。", 0);
          return;
        }
      } else {
        onSetAlert("エラー", "ログインしてください。", 0);
        return;
      }
    } catch (e) {
      onSetAlert(null, e, 0);
    } finally {
      onSetLoading(false);
    }
  };

  return (
    <Container component="main" sx={{ py: 12 }} maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography component="h1" variant="h5">
          プロフィール作成
        </Typography>
        <Box sx={{ mt: 4, width: "100%" }}>
          <EditProfile
            userData={userData}
            defaultData={{}}
            setUserData={setUserData}
          />
          <Button
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            onClick={handleComplete}
          >
            作成する
          </Button>
        </Box>
      </Box>
    </Container>
  );
};

export default Register;
