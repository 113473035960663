import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";

import React, { useEffect, useState } from "react";

const WForm = (props: any) => {
  return (
    <Box sx={{ width: "100%", my: 1 }}>
      <TextField
        fullWidth
        variant="filled"
        helperText={
          props.required
            ? props.value
              ? null
              : props.label
              ? `${props.label}を入力してください`
              : null
            : null
        }
        {...props}
      />
    </Box>
  );
};

export default WForm;
