import React from "react";
import { Stack, Box, TextField, Typography } from "@mui/material";
import moment from "moment";
import type { Event as EventInterface } from "../interface";
import "./components.css";
import { DateTimePicker } from "@mui/x-date-pickers-pro";
import WForm from "./WForm";
import WSelect from "./WSelect";
import location from "../data/location.json";

const PosterEdit = ({
  event,
  setEvent,
}: {
  event: EventInterface | null;
  setEvent: React.Dispatch<React.SetStateAction<EventInterface>>;
}) => {
  return (
    <Box>
      <Typography variant="subtitle1">詳細を入力</Typography>
      <Stack direction="column" spacing={2} sx={{ pt: 2 }}>
        <WForm
          required
          label="タイトル"
          variant="outlined"
          value={event?.eventName ?? ""}
          onChange={(e: any) => {
            setEvent((prevState) => ({
              ...prevState,
              eventName: e.target.value,
            }));
          }}
        />
        <WSelect
          required
          label="場所"
          variant="outlined"
          value={event?.locationId ?? ""}
          items={location.data.map((value, index) => {
            return { value: value.locationId, label: value.locationName };
          })}
          onChange={(e: any) => {
            setEvent((prevState) => ({
              ...prevState,
              locationId: e.target.value,
            }));
          }}
        />
        {event?.locationId === "" || event?.locationId === "99999" ? (
          <>
            <WForm
              required
              label="その他場所入力"
              variant="outlined"
              value={event?.location ?? ""}
              onChange={(e: any) => {
                setEvent((prevState) => ({
                  ...prevState,
                  location: e.target.value,
                }));
              }}
            />
          </>
        ) : (
          <></>
        )}
        <DateTimePicker
          label="開始時間"
          value={event?.eventTime?.timeFrom ?? ""}
          renderInput={(params) => <TextField {...params} />}
          onChange={(value: any) => {
            const time = {
              timeFrom: moment(value).format(),
              timeTo: event?.eventTime?.timeTo ?? "",
            };
            setEvent({
              ...event,
              eventTime: time,
            });
          }}
        />
        <DateTimePicker
          label="終了時間"
          value={event?.eventTime?.timeTo ?? ""}
          renderInput={(params) => <TextField {...params} />}
          onChange={(value: any) => {
            const time = {
              timeFrom: event?.eventTime?.timeFrom ?? "",
              timeTo: moment(value).format(),
            };
            setEvent({
              ...event,
              eventTime: time,
            });
          }}
        />
        <WForm
          label="詳細"
          variant="outlined"
          value={event?.content ?? ""}
          multiline
          onChange={(e: any) => {
            setEvent((prevState) => ({
              ...prevState,
              content: e.target.value,
            }));
          }}
          rows={5}
        />
        <WForm
          label="イベントリンク"
          variant="outlined"
          value={event?.url ?? ""}
          onChange={(e: any) => {
            setEvent((prevState) => ({
              ...prevState,
              url: e.target.value,
            }));
          }}
        />
      </Stack>
    </Box>
  );
};

export default PosterEdit;
