import { Box, Typography } from "@mui/material";

const StatusBox = ({ available }: { available: Boolean }) => {
  return (
    <Box className={`status ${available ? "available" : ""}`}>
      {available ? (
        <Typography variant="caption" fontWeight={600}>
          募集中
        </Typography>
      ) : (
        <Typography variant="caption" fontWeight={600}>
          開催終了
        </Typography>
      )}
    </Box>
  );
};
export default StatusBox;
