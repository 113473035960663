import { Typography, Box, Avatar, Stack, Chip } from "@mui/material";
import type { User as UserInterface } from "../interface";
import { School, VerifiedUser, WorkspacePremium } from "@mui/icons-material";
import { getSchoolName } from "../util";

const User = ({ user }: { user: UserInterface | null }) => {
  return (
    <>
      {user ? (
        <Box sx={{ borderTop: "1px solid rgba(0,0,0,.12)", py: 4 }}>
          <Typography variant="subtitle1">主催</Typography>
          <Stack direction="column" spacing={2} sx={{ pt: 2 }}>
            <Stack direction="row" spacing={2} alignItems="center">
              <Avatar
                sx={{ bgcolor: "secondary.main" }}
                alt={user.nickname}
                src={user.imagePath}
              >
                {user.nickname ? user.nickname[0] : ""}
              </Avatar>
              <Typography variant="h6">{user.nickname}</Typography>
            </Stack>
            {user.verified ? (
              <Chip
                color="success"
                icon={<VerifiedUser fontSize="inherit" sx={{ mr: 1 }} />}
                label="本人確認済み"
                variant="outlined"
                size="small"
              />
            ) : (
              <></>
            )}
            {user.premium ? (
              <Chip
                color="error"
                icon={<WorkspacePremium fontSize="inherit" sx={{ mr: 1 }} />}
                label="公式アカウント"
                variant="outlined"
                size="small"
              />
            ) : (
              <></>
            )}
            <Typography variant="body1">
              <School fontSize="inherit" sx={{ mr: 1 }} />
              {user.schoolId
                ? getSchoolName(user.schoolId)
                : "その他" ?? "その他"}
            </Typography>
            <Typography variant="body1">{user.profile}</Typography>
          </Stack>
        </Box>
      ) : (
        <></>
      )}
    </>
  );
};

export default User;
