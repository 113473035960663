import React, { useState, useRef, useEffect, useContext } from "react";
import {
  Button,
  Container,
  CssBaseline,
  Stack,
  Grid,
  ImageListItem,
  Radio,
  RadioGroup,
  FormControl,
  Box,
  Typography,
  Dialog,
  Toolbar,
  AppBar,
  Slide,
} from "@mui/material";
import {
  getEvents,
  getImage,
  postEvents,
  postImage,
  putEvent,
} from "../firebase";
import type {
  Event as EventInterface,
  Theme as ThemeInterface,
  User as UserInterface,
} from "../interface";
import arrange from "../data/arrange.json";
import Poster from "./Poster";
import User from "./User";
import "./components.css";
import UploadButton from "./UploadButton";
import Image from "mui-image";
import PosterEdit from "./PosterEdit";
import html2canvas from "html2canvas";
import { AuthContext } from "../auth";
import { AlertContext } from "../alert";
import { Navigate, useNavigate } from "react-router-dom";
import { TransitionProps } from "@mui/material/transitions";
import HomePoster from "./HomePoster";
import Cover from "../components/Cover";
import useWindowSize from "./useWindowSize";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const EventAdd = () => {
  const navigate = useNavigate();
  const { user, onSetLoading } = useContext(AuthContext);
  const { onSetAlert } = useContext(AlertContext);
  const [width, height] = useWindowSize();
  const [event, setEvent] = useState<EventInterface>({});
  const [theme, setTheme] = useState<ThemeInterface>(arrange.data[0]);
  const [image, setImage] = useState<any>();
  const [preview, setPreview] = useState<any>();
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [selectedTheme, setSelectedTheme] = React.useState("0");
  const [events, setEvents] = useState<EventInterface[]>([]);

  useEffect(() => {
    const getData = async () => {
      setEvent({
        userId: user?.uid ?? "",
      });
      setTheme(arrange.data["0"]);
    };
    getData();
  }, [user]);

  useEffect(() => {
    const onload = async () => {
      const data = await getEvents();
      if (data) {
        setEvents(data);
      }
    };
    if (events.length === 0) {
      onload();
    }
  }, [openDialog]);

  useEffect(() => {
    setTheme(arrange.data[Number(selectedTheme)]);
  }, [selectedTheme]);

  useEffect(() => {
    if (image) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreview(reader.result);
      };
      reader.readAsDataURL(image);
    } else {
      setPreview(null);
    }
  }, [image]);

  const validate = () => {
    let count = 0;
    if (event?.eventName === undefined || event?.eventName?.length === 0)
      count += 1;
    if (event?.location === undefined && event?.locationId === undefined)
      count += 1;
    if (
      event?.eventTime?.timeFrom === undefined ||
      event?.eventTime?.timeFrom.length === 0
    )
      count += 1;
    if (
      event?.eventTime?.timeTo === undefined ||
      event?.eventTime?.timeTo.length === 0
    )
      count += 1;

    if (count > 0) return `入力していない項目が${count}件あります。`;
    else return null;
  };

  const changeUploadFile = (e: any) => {
    const file = e.target.files[0];
    if (file && file.type.substring(0, 5) === "image") {
      setImage(file);
    } else {
      setImage(null);
    }
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  const onSaveEvent = async (x: string, y: string, rotate: string) => {
    onSetLoading(true);
    handleClose();
    try {
      const target = document.getElementById("poster");
      if (target) {
        html2canvas(target, {
          allowTaint: true,
          useCORS: true,
        }).then((canvas) => {
          canvas.toBlob(async (blob: Blob | null) => {
            if (blob) {
              const data = await postEvents({
                ...event,
                position: { x: x, y: y, rotate: rotate },
              });
              if (data) {
                const res = await postImage(
                  `events/${data?.eventId}.png`,
                  blob
                );
                if (res) {
                  const url = await getImage(`events/${data?.eventId}.png`);
                  const ress = await putEvent(data?.eventId, {
                    ...data,
                    imagePath: url,
                  });
                  navigate({
                    pathname: "/",
                  });
                  return;
                } else {
                  onSetLoading(false);
                  onSetAlert(null, "画像投稿に失敗しました。", 0);
                }
              } else {
                onSetLoading(false);
                onSetAlert(null, "イベント作成に失敗しました。", 0);
              }
            } else {
              onSetLoading(false);
              onSetAlert(null, "画像変換に失敗しました。", 0);
            }
          });
        });
      } else {
        onSetLoading(false);
      }
    } catch (e) {
      onSetAlert(null, e, 0);
      onSetLoading(false);
    }
  };

  const onSendDialog = () => {
    const message = validate();
    if (message) {
      onSetAlert(null, message, 0);
      return;
    }
    if (width <= 480) {
      onSaveEvent(
        String(50 + Math.floor(Math.random() * 600)),
        String(50 + Math.floor(Math.random() * 1100)),
        String(Math.floor(-25 + Math.random() * 50))
      );
    } else {
      setOpenDialog(true);
    }
  };

  return (
    <Container sx={{ py: 12 }} maxWidth="md" component="main">
      <CssBaseline />
      <Grid container>
        <Grid
          item
          xs={12}
          sm={12}
          md={8}
          sx={{
            fontFamily: theme?.global?.fontFamily,
            px: 2,
          }}
        >
          <Box alignItems="center" sx={{ py: 1 }} textAlign="right">
            <Button variant="contained" onClick={onSendDialog}>
              保存
            </Button>
          </Box>
          <Box
            alignItems="center"
            sx={{ my: 1, maxWidth: "100%", overflow: "scroll" }}
            id="poster"
          >
            {preview ? (
              <Image src={preview} className="poster" />
            ) : (
              <Poster event={event} theme={theme} onEdit={true} />
            )}
          </Box>
        </Grid>
        <Grid
          item
          justifyContent={"center"}
          xs={12}
          sm={12}
          md={4}
          sx={{ py: 2, px: 2 }}
        >
          <Typography variant="subtitle1">ポスターを作成</Typography>
          <Stack direction="column" spacing={4}>
            <Box>
              <Typography variant="subtitle1">
                ポスター画像をアップロード
              </Typography>
              <Stack
                direction="row"
                spacing={1}
                sx={{ pt: 2 }}
                justifyContent="strench"
              >
                <UploadButton
                  onChange={changeUploadFile}
                  upload="アップロード"
                />
                <Button
                  color="secondary"
                  onClick={() => setImage(null)}
                  disabled={preview ? false : true}
                >
                  画像削除
                </Button>
              </Stack>
            </Box>
            {preview ? (
              <></>
            ) : (
              <>
                <Typography variant="subtitle1">または</Typography>
                <Box overflow="scroll">
                  <Typography variant="subtitle1">テーマ選択</Typography>
                  <FormControl sx={{ pt: 2 }}>
                    <RadioGroup
                      value={selectedTheme}
                      onChange={(e) => {
                        setSelectedTheme(e.target.value);
                      }}
                    >
                      <Stack direction="row" className="themeChoose">
                        {arrange.data.map((value, index) => {
                          return (
                            <Radio
                              key={index}
                              value={index}
                              icon={
                                <ImageListItem
                                  sx={{
                                    borderRadius: "15px",
                                    overflow: "hidden",
                                  }}
                                >
                                  <img
                                    src={`${value.global.background}`}
                                    srcSet={`${value.global.background}`}
                                    loading="lazy"
                                  />
                                </ImageListItem>
                              }
                              checkedIcon={
                                <ImageListItem
                                  sx={{
                                    border: 4,
                                    borderRadius: "15px",
                                    overflow: "hidden",
                                  }}
                                >
                                  <img
                                    src={`${value.global.background}`}
                                    srcSet={`${value.global.background}`}
                                    loading="lazy"
                                  />
                                </ImageListItem>
                              }
                            />
                          );
                        })}
                      </Stack>
                    </RadioGroup>
                  </FormControl>
                </Box>
              </>
            )}
            <PosterEdit event={event} setEvent={setEvent} />
            <Box
              alignItems="center"
              sx={{ py: 1, width: "10-%" }}
              textAlign="right"
            >
              <Button variant="contained" fullWidth onClick={onSendDialog}>
                保存
              </Button>
            </Box>
          </Stack>
        </Grid>
      </Grid>
      <Dialog
        open={openDialog}
        onClose={handleClose}
        fullScreen
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <Button autoFocus color="inherit" onClick={handleClose}>
              閉じる
            </Button>
          </Toolbar>
        </AppBar>
        <Box id="board" style={{ backgroundColor: "#cbcbcb" }}>
          <Cover preview={preview} onSaveEvent={onSaveEvent} />
          <Box id="saveEventMap">
            {events?.map((even, index) =>
              even?.end !== true ? (
                <HomePoster event={even} key={index} />
              ) : (
                <span key={index}></span>
              )
            )}
          </Box>
        </Box>
      </Dialog>
    </Container>
  );
};

export default EventAdd;
