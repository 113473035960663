import school from "./data/school.json";
import location from "./data/location.json";

export const getSchoolName = (schoolId: string) => {
  const schoolName = school.data.filter(function (item, index) {
    if (item.schoolId.toString() === schoolId) return true;
  });
  if (schoolName.length === 0) return null;
  return schoolName[0].schoolName;
};

export const getLocationName = (locationId: string) => {
  const locationName = location.data.filter(function (item, index) {
    if (item.locationId.toString() === locationId) return true;
  });
  if (locationName.length === 0) return null;
  return locationName[0].locationName;
};
