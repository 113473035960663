import { useContext, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import {
  TextField,
  Typography,
  Button,
  Container,
  Box,
  CssBaseline,
  Avatar,
  FormControlLabel,
  Checkbox,
  Link,
  Grid,
} from "@mui/material";
import { AuthContext } from "../auth";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import EditAccount from "./EditAccount";
import type { UserLogin as UserLoginInterface } from "../interface";
import { signIn } from "../firebase";
import { AlertContext } from "../alert";

const Login = () => {
  return <Navigate to="/intro" />;

  const navigate = useNavigate();
  const { user, onSetLoading } = useContext(AuthContext);
  const { onSetAlert } = useContext(AlertContext);
  const [userLogin, setUserLogin] = useState<UserLoginInterface>({});

  const validate = () => {
    let count = 0;
    if (userLogin.email === undefined || userLogin.email?.length === 0)
      count += 1;
    if (userLogin.password === undefined || userLogin.password?.length === 0)
      count += 1;
    if (userLogin.password === undefined || userLogin.password?.length < 6)
      return `パスワードは6桁以上入力してください。`;

    if (count > 0) return `入力していない項目が${count}件あります。`;
    else return null;
  };

  if (user) {
    return <Navigate to="/" />;
  }

  const handleSubmit = async () => {
    try {
      onSetLoading(true);
      const message = validate();
      if (message) {
        onSetAlert(null, message, 0);
        return;
      }

      if (userLogin?.email && userLogin?.password) {
        const res = await signIn(userLogin.email, userLogin.password);
      }
    } catch (e) {
      onSetAlert(null, e, 0);
    } finally {
      onSetLoading(false);
    }
  };

  return (
    <Container component="main" sx={{ py: 12 }} maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: "error.main" }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          ログイン
        </Typography>
        <Box sx={{ mt: 1, width: "100%" }}>
          <EditAccount
            userLogin={userLogin}
            setUserLogin={setUserLogin}
            onConfirm={false}
          />
          <Button
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            onClick={handleSubmit}
          >
            ログイン
          </Button>
          {/* <Grid item xs>
              <Link href="#" variant="body2">
                Forgot password?
              </Link>
            </Grid> */}
          <Button
            fullWidth
            sx={{ mb: 2 }}
            color="secondary"
            onClick={() => {
              navigate({
                pathname: "/signup",
              });
              return;
            }}
          >
            新規登録
          </Button>
        </Box>
      </Box>
    </Container>
  );
};

export default Login;
