import moment from "moment-timezone";

const timestamp = (after = 0, format = "") => {
  if (format !== "") {
    return moment().tz("Asia/Tokyo").add(after, "m").format();
  }
  return moment().tz("Asia/Tokyo").add(after, "m").format(format);
};

export const dateTimeToString = (date: String, format = "") => {
  if (format !== "") {
    return moment.tz(date, "Asia/Tokyo").format(format);
  }
  return moment.tz(date, "Asia/Tokyo").format("YYYY/MM/DD HH:mm A");
};

export default timestamp;
