import { useEffect, useState, useContext } from "react";
import {
  Button,
  Container,
  CssBaseline,
  Stack,
  Grid,
  Typography,
  Link,
} from "@mui/material";
import { getEvent, getUser, putJoin } from "../firebase";
import type {
  Event as EventInterface,
  Theme as ThemeInterface,
  User as UserInterface,
  CheckinUser as CheckinUserInterface,
} from "../interface";
import Error404 from "./Error404";
import arrange from "../data/arrange.json";
import ShareLink from "./ShareLink";
import StatusBox from "./StatusBox";
import Poster from "./Poster";
import User from "./User";
import { AuthContext } from "../auth";
import UserJoin from "./UserJoin";
import "./components.css";
import { Navigate, useNavigate, useLocation } from "react-router-dom";
import EventDetail from "./EventDetail";
import { AlertContext } from "../alert";

const Event = () => {
  const navigate = useNavigate();
  const querys = new URLSearchParams(useLocation().search);
  const { user, onSetLoading } = useContext(AuthContext);
  const { onSetAlert } = useContext(AlertContext);
  const [checkinUsers, setCheckinUsers] = useState<UserInterface[]>([]);
  const [eventUser, setEventUser] = useState<UserInterface | null>(null);
  const [event, setEvent] = useState<EventInterface | null>(null);
  const [theme, setTheme] = useState<ThemeInterface>(arrange.data[0]);

  const getQuery = (query: string) => {
    const res = querys.get(query);
    return res;
  };

  const onJoin = () => {
    if (user)
      return checkinUsers.some((value) => {
        if (value?.userId) return value?.userId === user?.uid;
        else return false;
      });
    else return false;
  };

  const onAvailable = () => {
    if (event)
      return (
        event?.eventTime?.timeTo !== null &&
        new Date(String(event?.eventTime?.timeTo)) >= new Date()
      );
    return false;
  };

  useEffect(() => {
    onSetLoading(true);
    const getData = async () => {
      try {
        const eventId = getQuery("eventId");
        if (eventId) {
          const data = await getEvent(eventId);
          if (data) {
            setEvent(data);

            const user = await getUser(data.userId);
            if (user) setEventUser(user);

            if (data.checkinUsers?.length > 0) {
              const checkinUser = await Promise.all(
                data.checkinUsers
                  ?.filter((value: CheckinUserInterface) => {
                    if (user === undefined || user.userId === undefined)
                      return true;
                    return value?.userId !== user?.userId;
                  })
                  .map(async (value: CheckinUserInterface, index: number) => {
                    if (value && value.userId !== undefined) {
                      const checkinUser = await getUser(value.userId);
                      return checkinUser;
                    } else {
                      return {};
                    }
                  })
              );

              if (checkinUser) setCheckinUsers(checkinUser);
            }
          }
          setTheme(arrange.data["0"]);
        }
      } catch (e) {
        setEvent(null);
        onSetAlert(null, e, 0);
      } finally {
        onSetLoading(false);
      }
    };
    getData();
  }, [user]);

  return (
    <Container sx={{ py: 12 }} maxWidth="md" component="main">
      <CssBaseline />
      {user === null ? (
        <Grid
          sx={{
            textAlign: "center",
            color: "red",
          }}
        >
          <Typography>
            ログインなしでの利用は現在アップデート中です。こちらからログインお願いします👉
            <Link href="/login">ログイン</Link>
          </Typography>
        </Grid>
      ) : (
        <></>
      )}
      {event ? (
        <Grid container>
          <Grid
            item
            xs={12}
            sm={12}
            md={event.imagePath ? 6 : 12}
            sx={{
              fontFamily: theme?.global?.fontFamily,
              px: 2,
            }}
          >
            <Grid
              container
              sx={{
                justifyContent: "space-between",
                pb: 2,
              }}
            >
              <StatusBox available={onAvailable()} />
              <ShareLink />
            </Grid>
            {event.imagePath ? (
              <Grid item xs={12} className="poster" id="poster">
                <Poster event={event} theme={theme} onEdit={false} />
              </Grid>
            ) : (
              <></>
            )}
          </Grid>
          <Grid
            item
            justifyContent={"center"}
            xs={12}
            sm={12}
            md={event.imagePath ? 6 : 12}
            sx={{ py: 8, px: 2 }}
          >
            <Stack direction="column" spacing={4}>
              {onAvailable() === false ? (
                <Button variant="contained" color="primary" disabled>
                  開催終了しました
                </Button>
              ) : onJoin() ? (
                <Button variant="contained" color="primary" disabled>
                  申し込み済み
                </Button>
              ) : event.userId === user?.uid ? (
                <Button variant="contained" color="primary" disabled>
                  主催者です
                </Button>
              ) : (
                <Button
                  variant="contained"
                  onClick={async () => {
                    onSetLoading(true);
                    try {
                      if (event?.eventId && user?.uid) {
                        const res = await putJoin(event?.eventId, user?.uid);
                        if (res) {
                          navigate(0);
                        }
                        return;
                      }
                    } catch (e) {
                      onSetAlert(null, e, 0);
                    } finally {
                      onSetLoading(false);
                    }
                  }}
                >
                  参加する
                </Button>
              )}
              <UserJoin users={checkinUsers} />
              <EventDetail event={event} />
              <User user={eventUser} />
            </Stack>
          </Grid>
        </Grid>
      ) : (
        <Error404 />
      )}
    </Container>
  );
};

export default Event;
